import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { GameSubRoute } from '../../../config/routes';
import { HomeScreen } from './HomeScreen';
import { EventScreen } from './EventScreen';
import { ChooseUsernameScreen } from './ChooseUsernameScreen';
import { withDependencyGuard } from '../../helpers/dependencyGuard';

const GameStack = createNativeStackNavigator();

export const GameScreen = withDependencyGuard(['isLoadingPrivateUserData'], ({ context }) => (
    <GameStack.Navigator
        screenOptions={{
            header: () => null,
            animation: 'none',
        }}
    >
        {context?.privateUserData?.username ? (
            <>
                <GameStack.Screen name={GameSubRoute.HomeScreen} component={HomeScreen} />
                <GameStack.Screen name={GameSubRoute.EventScreen} component={EventScreen} />
            </>
        ) : (
            <GameStack.Screen name={GameSubRoute.ChooseUsernameScreen} component={ChooseUsernameScreen} />
        )}
    </GameStack.Navigator>
));
