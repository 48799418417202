import { StatusBar } from 'expo-status-bar';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { linking, Route } from './config/routes';
import React, { useEffect } from 'react';
import { registerDevice } from './lib/mobileNotifications';
import { Platform } from 'react-native';
import { RouteDataGate } from './app/components/RouteDataGate';
import { GameScreen } from './app/flows/game/GameScreen';
import { NotFoundScreen } from './app/flows/NotFoundScreen';
import { SignUpScreen } from './app/flows/signUp/SignUpScreen';
import { signOut } from './app/firebase/auth';

const Stack = createNativeStackNavigator();

export default function App() {
    useEffect(() => {
        if (Platform.OS !== 'web') {
            registerDevice();
        }
        // TODO: used for testing, uncomment to sign out
        console.log(signOut);
        // signOut();
    }, []);

    return (
        <>
            <NavigationContainer linking={linking}>
                <Stack.Navigator
                    screenOptions={{
                        header: () => null,
                        animation: 'none',
                    }}
                >
                    <Stack.Screen name={Route.RouteDataGate}>
                        {() => (
                            <RouteDataGate>
                                {(InnerStack, context) => {
                                    return (
                                        <>
                                            {context?.signedUserData ? (
                                                <InnerStack.Screen name={Route.GameScreen} component={GameScreen} />
                                            ) : (
                                                <InnerStack.Screen name={Route.SignUpScreen} component={SignUpScreen} />
                                            )}
                                            <InnerStack.Screen name={Route.NotFoundScreen} component={NotFoundScreen} />
                                        </>
                                    );
                                }}
                            </RouteDataGate>
                        )}
                    </Stack.Screen>
                </Stack.Navigator>
            </NavigationContainer>
            <StatusBar style="auto" />
        </>
    );
}
