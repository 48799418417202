import { Timestamp, DocumentReference } from 'firebase/firestore';

export enum EventState {
    Completed = 'COMPLETED',
    WaitingForUsers = 'WAITING_FOR_USERS',
    WaitingForChallenge = 'WAITING_FOR_CHALLENGE',
    ChallengeActive = 'CHALLENGE_ACTIVE',
}

/**
 * @deprecated
 * Use EventState instead, not sure why I made this enum challenge state but we keep it for backwards compatibility
 * */
export enum ChallengeState {
    COMPLETED = 'COMPLETED',
    PASSWORD_CHALLENGE = 'PASSWORD_CHALLENGE',
    WAITING_CHALLENGE = 'WAITING_CHALLENGE',
}

export const GENERAL_TOPIC = 'general';

export enum FirebaseCollection {
    PublicUsers = 'publicUsers',
    PrivateUsers = 'privateUsers',
    Events = 'events',
    EventContestants = 'contestants',
    Challenges = 'challenges',
    ScreenOfFame = 'screenOfFame',
    PrivateUsersPurchases = 'purchases',
    FasterHintRevealBuyers = 'fasterHintRevealBuyers',
}

export const EVENT_INITIAL_CHALLENGE_UNLOCK_MILLISECONDS = 10 * 60 * 1000; // 10 min

export const FASTER_HINT_REVEAL_SKU = 'com.solve63.ios.FasterHintReveal';
export const CHALLENGE_ATTEMPT_DELAY = 3 * 1000;
export const CHALLENGES_LEADER_RELEVANT_DISTANCE = 7 * 60 * 60 * 1000;

export const HINT_BASE_WAIT = 3 * 60 * 60 * 1000; // 3 hours
export const FASTER_HINT_REVEAL_BUYER_COLLECTION = 'fasterHintRevealBuyers';

export enum APIFunctionsName {
    COMPLETE_USER_PROFILE = 'completeUserProfile',
    ENROLL_USER_TO_EVENT = 'enrollUserToEvent',
    GET_EVENT_STATE = 'getEventState',
    GET_PASSWORD_CHALLENGE = 'getPasswordChallenge',
    CHECK_PASSWORD_CHALLENGE = 'checkPasswordChallenge',
    GET_PASSWORD_CHALLENGE_HINTS = 'getPasswordChallengeHints',
}

export enum EventUnlockMethod {
    CONTESTANT_COUNT = 'CONTESTANT_COUNT',
    TIME = 'TIME',
}

export interface Hint {
    text?: string;
    unlockTimestamp?: number;
}

export interface PrivateUserData {
    username: string;
    eventsCache: {
        [key: string]: {
            timestamp: number;
            completed?: boolean;
            nextUnlockTimestamp?: number;
        };
    };
}

export interface Link {
    text: string;
    url: string;
}
export interface ScreenOfFameEntry {
    event: string;
    imageUrl: string;
    links: Link[];
    phrase: string;
    user: string;
}

export enum EventVisibility {
    Public = 'public',
    Beta = 'beta',
    Private = 'private',
}

export interface EventUnlockOptions {
    method: EventUnlockMethod;
    requiredContestants: number;
}

export interface EventChallengeReference {
    reference: DocumentReference;
}

export interface Event {
    key: string;
    challenges: EventChallengeReference[];
    completed: boolean;
    completedContestantCount: number;
    contestantCount: number;
    unlocked: boolean;
    unlockedAtTimestamp: Timestamp;
    createdAt: Timestamp;
    unlockOptions: EventUnlockOptions;
    visibility: EventVisibility;
}

export interface EventRequest {
    eventKey: string;
}

export interface ChallengeRequest extends EventRequest {
    challengeKey: string;
}

export interface CompleteUserProfileRequest {
    username: string;
}

export interface CompleteUserProfileResponse {
    success: boolean;
}

export type EnrollUserToEventRequest = EventRequest;

export type GetPasswordChallengeRequest = EventRequest;

export type GetEventStateRequest = EventRequest;

/**
 * @deprecated
 */
export interface GetPasswordChallengeResponse {
    challengeState: ChallengeState;
    finalPosition?: number;
    unlockTimestamp?: number;
    stage?: number;
    challengeKey?: string;
    url?: string;
    prompt?: string;
}

export interface Challenge {
    key: string;
    url: string;
    prompt: string;
}

export interface GetEventStateResponse<T = EventState> {
    state: T;
    stateData: T extends EventState.Completed
        ? EventStateCompletedData
        : T extends EventState.WaitingForUsers
        ? EventStateWaitingForUsersData
        : T extends EventState.WaitingForChallenge
        ? EventStateWaitingForChallengeData
        : T extends EventState.ChallengeActive
        ? EventStateChallengeActiveData
        : never;
}

export interface EventStateCompletedData {
    finalPosition: number;
}

export interface EventStateWaitingForUsersData {
    contestantCount: number;
}

export interface EventStateWaitingForChallengeData {
    stage: number;
    unlockTimestamp: number;
}

export interface EventStateChallengeActiveData {
    stage: number;
    challengeKey: string;
    challenge: Challenge;
}

export interface CheckPasswordChallengeRequest extends ChallengeRequest {
    password: string;
}

export interface CheckPasswordChallengeResponse {
    isFinalStage?: boolean;
    /**
     * @deprecated use isCorrect instead
     */
    correct: boolean;
    isCorrect: boolean;
    possibleLeader: unknown;
    scheduledNotifications: Record<string, string>;
}

export type GetPasswordChallengeHintsRequest = ChallengeRequest;

export interface GetPasswordChallengeHintsResponse {
    possibleHints: Hint[];
    hasBoughtFasterHintReveal: boolean;
}

export interface LocalAppData {
    onboardingStep: number;
}

// Firebase models

export interface FirebaseEvent {
    challenges: {
        reference: DocumentReference;
    }[];
    completed: boolean;
    contestantCount: number;
    completedContestantCount: number;
    unlocked: boolean;
    unlockedAtTimestamp: Timestamp;
    visibility: EventVisibility;
}

export interface SignedUserData {
    uid: string;
    displayName: string | null;
    email: string | null;
}

// Route data gate types
export interface AppRouteData {
    localAppData: LocalAppData;
}

export interface SignedInRouteData extends AppRouteData {
    signedUserData: SignedUserData;
    privateUserData?: PrivateUserData;
}

export interface EventRouteData extends SignedInRouteData {
    event: Event;
    eventState: GetEventStateResponse;
}

export interface ChallengeRouteData extends EventRouteData {
    passwordChallengeHintsData: GetPasswordChallengeHintsResponse;
}

export interface RouteDataGateContextData extends Partial<ChallengeRouteData> {
    isLoadingLocalAppData: boolean;
    isLoadingAuthUserData: boolean;
    isLoadingPrivateUserData: boolean;
    isSubscribingToEvent: boolean;
    isLoadingEventState: boolean;
    isLoadingPasswordChallengeHints: boolean;
}
