import { LocalAppData } from './types';
import storage from '../config/storage';

const DEFAULT_DATA: LocalAppData = {
    onboardingStep: 0,
};

const LOCAL_DATA_KEY = 'localAppData';

// load object with key and id
export const loadLocalAppData = async () => {
    // Load something with key only. (using only a keyname but no id)
    // This key should be unique. This is for data frequently used.
    // The key and value pair is permanently stored unless you remove it yourself.

    let data: LocalAppData = DEFAULT_DATA;
    try {
        data = await storage.load<LocalAppData>({
            key: LOCAL_DATA_KEY, // Note: Do not use underscore("_") in key!
        });

        return data;
    } catch (e) {
        if (e.name === 'NotFoundError') {
            // not found
            return DEFAULT_DATA;
        }

        throw e;
    }

    return data;
};

// save oject with key and id
export const saveLocalAppData = async (data: Partial<LocalAppData>) => {
    let currentData: LocalAppData = DEFAULT_DATA;

    try {
        currentData = await loadLocalAppData();
    } catch (e) {
        console.log(e);
    }

    // Save something with key only. (using only a keyname but no id)
    // This key should be unique. This is for data frequently used.
    // The key and value pair is permanently stored unless you remove it yourself.

    return storage.save({
        key: LOCAL_DATA_KEY, // Note: Do not use underscore("_") in key!
        data: {
            ...currentData,
            ...data,
        },
    });
};
