import firebase from 'firebase/compat';
import { webFirestore } from '../../config/firebase.web';

import { CommonDocumentData, CommonQueryFromPathOptions } from './commonAPI';

export const snapshotFromPath = <T extends CommonDocumentData>({
    path,
    options,
    onError = () => {},
    onNext = () => {},
    onNextCollection = () => {},
}: CommonQueryFromPathOptions<T>): (() => void) => {
    if (!path) {
        return () => {};
    }

    const pathParts = path.split('/');

    let isLastPartDocument = true;

    let ref: firebase.firestore.DocumentReference<T> | firebase.firestore.Query<T> | null = null;

    for (const part of pathParts) {
        if (!isLastPartDocument) {
            ref = ((ref as firebase.firestore.CollectionReference<T>) ?? webFirestore).doc(
                part,
            ) as firebase.firestore.DocumentReference<T>;
            isLastPartDocument = true;
        } else {
            ref = ((ref as firebase.firestore.DocumentReference<T>) ?? webFirestore).collection(
                part,
            ) as firebase.firestore.CollectionReference<T>;
            isLastPartDocument = false;
        }
    }

    let subscription: () => void;

    if (isLastPartDocument) {
        const docRef = ref as firebase.firestore.DocumentReference<T>;
        return docRef.onSnapshot(
            (snapshot) => {
                const data = snapshot.data() as T;
                onNext({ key: snapshot.id, ...data });
            },
            (e) => {
                onError(e);
            },
        );
    }

    let collectionRef = ref as firebase.firestore.Query<T>;

    if (options?.orderBy) {
        for (const [field, direction] of options.orderBy) {
            collectionRef = collectionRef.orderBy(field, direction);
        }
    }

    if (options?.limit) {
        collectionRef = collectionRef.limit(options.limit);
    }

    if (options?.where) {
        for (const [field, operator, value] of options.where) {
            collectionRef = collectionRef.where(field, operator as any, value);
        }
    }

    subscription = collectionRef.onSnapshot(
        (snapshot) => {
            const data = snapshot.docs.map((d) => {
                return { key: d.id, ...d.data() };
            });
            onNextCollection(data);
        },
        (e) => {
            onError(e);
        },
    );

    return subscription;
};
