import React from 'react';
import { loadLocalAppData } from '../../lib/storageHelpers';
import { LocalAppData } from '../../lib/types';

export const useLocalAppData = (): [LocalAppData | null, boolean, Error | null, () => void] => {
    const [localAppData, setLocalAppData] = React.useState<LocalAppData | null>(null);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<Error | null>(null);

    const load = () => {
        loadLocalAppData()
            .then((data) => {
                setLocalAppData(data);
            })
            .catch((e) => {
                setError(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    React.useEffect(() => {
        load();
    }, []);

    return [localAppData, loading, error, load];
};
