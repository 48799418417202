import React, { useContext } from 'react';
import { ChallengeRouteData, RouteDataGateContextData } from '../../lib/types';
import { RouteDataGateContext } from '../components/RouteDataGate';

/**
 * This is a helper function helps us with the web version of the app.
 * This prevents the app from rendering the screen before the data is ready.
 * Without this, the app would try to render screens that cannot be rendered yet and thus reset the app's url.
 * @param dependencies
 * @param Component
 * @returns
 */
export function withDependencyGuard<T extends JSX.IntrinsicAttributes>(
    dependencies: (keyof Exclude<RouteDataGateContextData, ChallengeRouteData>)[],
    Component: React.ComponentType<T & { context: RouteDataGateContextData }>,
): React.FC<T> {
    return (props: T) => {
        const context = useContext(RouteDataGateContext);
        if (!context || dependencies.some((dependency) => context[dependency])) {
            return null;
        }

        return <Component {...props} context={context} />;
    };
}
