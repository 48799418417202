import { LinkingOptions, PathConfigMap } from '@react-navigation/native';

export enum Route {
    GameScreen = 'GameScreen', // entry point
    SignUpScreen = 'SignUpScreen',
    NotFoundScreen = 'NotFoundScreen',
    RouteDataGate = 'RouteDataGate',
}

export enum GameSubRoute {
    HomeScreen = 'HomeScreen',
    ChooseUsernameScreen = 'ChooseUsernameScreen',
    EventScreen = 'EventScreen',
}

export enum EventSubRoute {
    ChallengeScreen = 'ChallengeScreen',
    EventLeaderboardScreen = 'EventLeaderboardScreen',
}

export enum ChallengeSubRoute {
    SolveChallengeScreen = 'SolveChallengeScreen',
    HintsScreen = 'HintsScreen',
    ChallengeCompletedScreen = 'ChallengeCompletedScreen',
}
export enum ChallengeCompletedSubRoute {
    ChallengeLeaderboardScreen = 'ChallengeLeaderboardScreen',
}
export const URLS_BY_ROUTE: PathConfigMap<{}> = {
    // this is a special route that is used to gate access to routes that require data or authentication. Each route's requirements are commented above it.
    [Route.RouteDataGate]: {
        path: '',
        exact: false,
        screens: {
            [Route.GameScreen]: {
                // needs to be signed in
                // needs to have fetched a valid private user data
                path: '',
                screens: {
                    [GameSubRoute.HomeScreen]: {
                        path: '',
                    },
                    [GameSubRoute.ChooseUsernameScreen]: {
                        path: 'choose-username',
                    },
                    [GameSubRoute.EventScreen]: {
                        // needs to have fetched a valid event
                        //needs to have fetched a valid event state
                        path: '/event/:eventKey',
                        screens: {
                            [EventSubRoute.ChallengeScreen]: {
                                // needs to have fetched a valid challenge
                                path: '/challenge', // inside this screen it will live the state in which we load and display correct or incorrect password. After that we show the leaderboard
                                screens: {
                                    [ChallengeSubRoute.SolveChallengeScreen]: '',
                                    [ChallengeSubRoute.HintsScreen]: '/hints',
                                    [ChallengeSubRoute.ChallengeCompletedScreen]: {
                                        path: '/:challengeKey',
                                        screens: {
                                            [ChallengeCompletedSubRoute.ChallengeLeaderboardScreen]: '/leaderboard',
                                        },
                                    },
                                },
                            },
                            [EventSubRoute.EventLeaderboardScreen]: '/leaderboard',
                        },
                    },
                },
            },
            [Route.SignUpScreen]: '/sign-up',
            [Route.NotFoundScreen]: '*',
        },
    },
};

export type RootStackParamList = {
    [GameSubRoute.EventScreen]: {
        eventKey: string;
        screen: EventSubRoute;
        params: {
            screen: ChallengeSubRoute;
        };
    };
};

export const linking: LinkingOptions<RootStackParamList> = {
    prefixes: [],
    config: {
        screens: { ...URLS_BY_ROUTE },
    },
};

// Make the default RootParamList the same as the RootStackParamList
declare global {
    namespace ReactNavigation {
        interface RootParamList extends RootStackParamList {}
    }
}
