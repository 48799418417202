import { useEffect, useState } from 'react';
import { StyleSheet, View, Text, TextInput, Button } from 'react-native';
import { useCompleteUserProfile } from '../../../hooks/sixtyThreeAPIHooks';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export const ChooseUsernameScreen: React.FC = () => {
    const [username, setUsername] = useState('');

    const [createUsername, isChecking, error] = useCompleteUserProfile();

    useEffect(() => {
        if (error) {
            console.log(error);
        }
    }, [error]);

    return (
        <View style={styles.container}>
            <Text>Choose username</Text>
            {isChecking && <Text>Checking username availability</Text>}
            {error && <Text>{JSON.stringify(error)}</Text>}
            <TextInput
                value={username}
                onChangeText={setUsername}
                style={{
                    padding: 10,
                    margin: 10,
                    backgroundColor: 'blue',
                    color: 'white',
                }}
            />
            <Button
                title="create user"
                onPress={() =>
                    createUsername({
                        username,
                    })
                }
            />
        </View>
    );
};
