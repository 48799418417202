import firebase from 'firebase/compat/app';
import { webAuth } from '../../config/firebase.web';

import {
    CommonAuthStateChangedFunction,
    CommonSignInWithAppleFunction,
    CommonSignInWithGoogleFunction,
    CommonSignOutFunction,
    CommonUser,
    CommonUserCredential,
} from './commonAPI';

export const onAuthStateChanged: CommonAuthStateChangedFunction = (callback: (user: CommonUser | null) => void) => {
    return webAuth.onAuthStateChanged((user) => {
        if (user) {
            callback({
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
                photoURL: user.photoURL,
            });
        } else {
            callback(null);
        }
    });
};

export const signOut: CommonSignOutFunction = () => {
    return webAuth.signOut();
};

export const signInWithApple: CommonSignInWithAppleFunction = async (): Promise<CommonUserCredential> => {
    const provider = new firebase.auth.OAuthProvider('apple.com');

    provider.addScope('email');
    provider.addScope('name');

    provider.setCustomParameters({
        // Localize your app in English or the language you want to support.
        locale: 'en',
    });

    const result = await webAuth.signInWithPopup(provider);

    if (!result.user) {
        throw new Error('Something went wrong obtaining access token');
    }

    const userCredential: CommonUserCredential = {
        user: {
            uid: result.user.uid,
            displayName: result.user.displayName,
            email: result.user.email,
            photoURL: result.user.photoURL,
        },
    };

    return userCredential;
};

export const signInWithGoogle: CommonSignInWithGoogleFunction = async (): Promise<CommonUserCredential> => {
    const provider = new firebase.auth.GoogleAuthProvider();

    provider.addScope('profile');

    const result = await webAuth.signInWithPopup(provider);

    if (!result.user) {
        throw new Error('Something went wrong obtaining access token');
    }

    const userCredential: CommonUserCredential = {
        user: {
            uid: result.user.uid,
            displayName: result.user.displayName,
            email: result.user.email,
            photoURL: result.user.photoURL,
        },
    };

    return userCredential;
};
