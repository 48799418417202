import React from 'react';
import { CommonHTTPCallableReturnType } from './commonAPI';
import { webFunctions } from '../../config/firebase';

export const useCommonHttpCallable = <T, R>(name: string): CommonHTTPCallableReturnType<T, R> => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState<Error | undefined>(undefined);
    const [result, setResult] = React.useState<R | undefined>(undefined);

    const callable = async (data: T | undefined): Promise<R> => {
        setIsLoading(true);
        setError(undefined);
        setResult(undefined);
        try {
            const response = await webFunctions.httpsCallable(name)(data);
            setIsLoading(false);
            setResult(response.data);
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setError(e);
            throw e;
        }
    };

    return [callable, isLoading, error, result];
};
