import messaging from '@react-native-firebase/messaging';
import { Platform } from 'react-native';
import PushNotificationIOS from '@react-native-community/push-notification-ios';
import { isEmpty } from 'lodash';
import { GENERAL_TOPIC } from './types';

export const handleNotificationScheduleRequest = (data) => {
    if (!data || isEmpty(data)) {
        return;
    }
    const { schedule, title, body } = data;
    if (Platform.OS === 'ios') {
        PushNotificationIOS.addNotificationRequest({
            id: schedule,
            body,
            title,
            isSilent: false,
            fireDate: schedule,
            // sound: 'notif.wav',
        });
    }
};

export const registerDevice = async () => {
    const permission = await messaging().hasPermission();
    if (permission === 0 || permission === -1) {
        await messaging().requestPermission({
            provisional: true,
        });
    }

    messaging().setBackgroundMessageHandler((payload) => {
        const { data } = payload;
        handleNotificationScheduleRequest(data);
        return Promise.resolve();
    });

    messaging().onMessage((payload) => {
        const { data } = payload;
        handleNotificationScheduleRequest(data);
    });
};

export const requestNonProvisionalPermission = async () => {
    const permission = await messaging().hasPermission();
    if (permission !== 1) {
        await messaging().requestPermission();
    }
    await messaging().subscribeToTopic(GENERAL_TOPIC);

    // const checkToken = async () => {
    //     const fcmToken = await messaging().getToken();
    //     if (fcmToken) {
    //         console.log(fcmToken);
    //     }
    // };

    // checkToken();
};
