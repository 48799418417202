import { Link } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useContext, useState } from 'react';
import { Button, Image, StyleSheet, Text, TextInput, View } from 'react-native';
import { ChallengeSubRoute, EventSubRoute, GameSubRoute } from '../../../config/routes';
import { useCheckPasswordChallenge } from '../../../hooks/sixtyThreeAPIHooks';
import { EventState, GetEventStateResponse } from '../../../lib/types';
import { RouteDataGateContext } from '../../components/RouteDataGate';
import { HintsScreen } from './HintsScreen';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const ChallengeStack = createNativeStackNavigator();

export const ChallengeScreen: React.FC = () => {
    const context = useContext(RouteDataGateContext);

    const eventKey = context?.event?.key;

    const eventState = context?.eventState as GetEventStateResponse<EventState.ChallengeActive>;

    const stateData = eventState.stateData;

    const { challenge } = stateData;

    const [password, setPassword] = useState<string>('');

    const [checkPasswordChallenge, isCheckingResponse, checkPasswordChallengeError, checkPasswordResponse] =
        useCheckPasswordChallenge();

    if (isCheckingResponse) {
        return <Text>Checking result...</Text>;
    }

    return (
        <ChallengeStack.Navigator
            screenOptions={{
                header: () => null,
                animation: 'none',
            }}
        >
            <ChallengeStack.Screen name={ChallengeSubRoute.SolveChallengeScreen}>
                {() => (
                    <View style={styles.container}>
                        <Text>Challenge</Text>
                        <Image style={{ width: '100%', height: '50%' }} source={{ uri: challenge.url }} />
                        <TextInput
                            value={password}
                            onChangeText={setPassword}
                            style={{
                                padding: 10,
                                margin: 10,
                                minWidth: 100,
                                backgroundColor: 'blue',
                                color: 'white',
                            }}
                        />

                        {checkPasswordChallengeError && <Text>{checkPasswordChallengeError.message}</Text>}
                        {checkPasswordResponse && (
                            <Text>{checkPasswordResponse.isCorrect ? 'Correct' : 'Incorrect'}</Text>
                        )}
                        <Text>{challenge.prompt}</Text>
                        <Button
                            title="answer challenge"
                            onPress={() => {
                                if (eventKey) {
                                    checkPasswordChallenge({
                                        eventKey,
                                        challengeKey: challenge.key,
                                        password,
                                    });
                                }
                            }}
                        />

                        {eventKey && (
                            <Link
                                to={{
                                    screen: GameSubRoute.EventScreen,
                                    params: {
                                        eventKey: eventKey,
                                        screen: EventSubRoute.ChallengeScreen,
                                        params: {
                                            screen: ChallengeSubRoute.HintsScreen,
                                        },
                                    },
                                }}
                            >
                                <Text>Ask for a hint</Text>
                            </Link>
                        )}
                    </View>
                )}
            </ChallengeStack.Screen>
            <ChallengeStack.Screen name={ChallengeSubRoute.HintsScreen} component={HintsScreen} />
            <ChallengeStack.Screen name={ChallengeSubRoute.ChallengeCompletedScreen}>
                {() => (
                    <View>
                        {/* There should be another container component maybe with the ChallengeCompletedSubRoute routes */}
                        <Text>Needs route...</Text>
                    </View>
                )}
            </ChallengeStack.Screen>
        </ChallengeStack.Navigator>
    );
};
