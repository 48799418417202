import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useContext } from 'react';
import { View, Text } from 'react-native';
import { EventSubRoute } from '../../../config/routes';
import { EventState } from '../../../lib/types';
import { RouteDataGateContext } from '../../components/RouteDataGate';
import { ChallengeScreen } from './ChallengeScreen';

const EventStack = createNativeStackNavigator();

export const EventScreen: React.FC = () => {
    const context = useContext(RouteDataGateContext);

    const eventState = context?.eventState;

    return eventState?.state === EventState.Completed ? (
        <View>
            {/* If the challenge is completed, we'll try to get the challenge key from the params so we can display the leaderboard of that stage. */}
            <Text>Completed</Text>
        </View>
    ) : eventState?.state === EventState.WaitingForChallenge ? (
        <View>
            <Text>Waiting for challenge</Text>
        </View>
    ) : eventState?.state === EventState.WaitingForUsers ? (
        <View>
            <Text>Waiting for users</Text>
        </View>
    ) : eventState?.state === EventState.ChallengeActive ? (
        <EventStack.Navigator
            screenOptions={{
                header: () => null,
                animation: 'none',
            }}
        >
            <EventStack.Screen name={EventSubRoute.ChallengeScreen} component={ChallengeScreen} />
        </EventStack.Navigator>
    ) : (
        <Text>This is a bug. 63 chars.</Text>
    );
};
