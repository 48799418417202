import { useContext } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { RouteDataGateContext } from '../../components/RouteDataGate';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export const HintsScreen: React.FC = () => {
    const context = useContext(RouteDataGateContext);

    return (
        <View style={styles.container}>
            <Text>Hints</Text>
            <Text>{JSON.stringify(context?.passwordChallengeHintsData)}</Text>
        </View>
    );
};
