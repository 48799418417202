import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import { emulators } from '../firebase.json';
import { app, IP_ADDRESS, webAuth } from './firebaseCommon';

export { webAuth };

export const webFirestore = (() => {
    const f = app.firestore();
    if (process.env.NODE_ENV === 'development') f.useEmulator(IP_ADDRESS, emulators.firestore.port);
    return f;
})();

export const webFunctions = (() => {
    const f = app.functions();
    if (process.env.NODE_ENV === 'development') f.useEmulator(IP_ADDRESS, emulators.functions.port);
    return f;
})();
