import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { emulators } from '../firebase.json';

export const IP_ADDRESS = '192.168.100.57' ?? 'localhost';
export const generateLocalAddress = (port: number) => {
    return `http://${IP_ADDRESS}:${port}`;
};

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: 'AIzaSyBie1popmFFzqkXRagEiuD35dss4DuGjts',
    authDomain: 'sixty-three-app.firebaseapp.com',
    databaseURL: 'https://sixty-three-app.firebaseio.com',
    projectId: 'sixty-three-app',
    storageBucket: 'sixty-three-app.appspot.com',
    messagingSenderId: '162272961114',
    appId: '1:162272961114:web:4ca915d9161447c8a3ee63',
    measurementId: 'G-TTL0SHG57G',
};

export const app = firebase.initializeApp(firebaseConfig);

export const webAuth = (() => {
    const a = app.auth();
    if (process.env.NODE_ENV === 'development') a.useEmulator(generateLocalAddress(emulators.auth.port));
    return a;
})();
