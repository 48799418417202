import React from 'react';
import { CommonDocumentData, CommonFirebaseRefFromPathOptions, CommonUseDocumentDataResult } from './commonAPI';
import { snapshotFromPath } from './firestore';

export const useCommonDocumentData = <T extends CommonDocumentData>(path?: string): CommonUseDocumentDataResult<T> => {
    const [data, setData] = React.useState<T | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<Error | undefined>(undefined);

    React.useEffect(() => {
        if (!path) {
            return;
        }
        const unsubscribe = snapshotFromPath<T>({
            path,
            onError: (e) => {
                setError(e);
                setLoading(false);
            },
            onNext: (d) => {
                setData(d);
                setLoading(false);
            },
            onNextCollection: () => {
                setLoading(false);
            },
        });

        return () => {
            unsubscribe();
        };
    }, [path]);

    return [data, loading, error];
};

export const useCommonCollectionData = <T extends CommonDocumentData>(
    path: string,
    options?: CommonFirebaseRefFromPathOptions,
): CommonUseDocumentDataResult<T[]> => {
    const [data, setData] = React.useState<T[] | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<Error | undefined>(undefined);

    React.useEffect(() => {
        if (!path) {
            return;
        }
        const unsubscribe = snapshotFromPath<T>({
            path,
            options,
            onError: (e) => {
                setError(e);
                setLoading(false);
            },
            onNextCollection: (d) => {
                setData(d);
                setLoading(false);
            },
        });

        return () => {
            unsubscribe();
        };
        // options is not listed as a dependency because it is not expected to change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    return [data, loading, error];
};
