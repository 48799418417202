import { Link } from '@react-navigation/native';
import { Button, StyleSheet, Text, View } from 'react-native';
import { GameSubRoute } from '../../../config/routes';
import { signOut } from '../../../app/firebase/auth';
import { useSubscribeToCurrentEvent, useSubscribeToPastEvents } from '../../../hooks/sixtyThreeAPIHooks';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        backgroundColor: 'lightgrey',
        padding: 10,
        margin: 10,
    },
});

// the main screen of the game after the user is signed in
export const HomeScreen: React.FC = () => {
    const [currentEvent, isLoadingCurrentEvent] = useSubscribeToCurrentEvent();

    const [pastEvents, isLoadingPastEvents] = useSubscribeToPastEvents();

    const isLoading = isLoadingCurrentEvent || isLoadingPastEvents;

    return (
        <View style={styles.container}>
            <Text>Signed game</Text>

            <Text>{isLoading ? 'Loading' : 'Loaded'}</Text>
            {currentEvent && (
                <View style={styles.card}>
                    <Link
                        to={{
                            screen: GameSubRoute.EventScreen,
                            params: {
                                eventKey: currentEvent.key,
                            },
                        }}
                    >
                        <Text>{currentEvent.completed ? 'Completed' : 'Not completed'}</Text>
                        <Text>Unlocked: {currentEvent.unlocked ? 'Yes' : 'No'}</Text>
                        <Text>Unlocked at: {currentEvent.unlockedAtTimestamp.toDate().toISOString()}</Text>
                        <Text>Unlock options: {JSON.stringify(currentEvent.unlockOptions)}</Text>
                    </Link>
                </View>
            )}
            {pastEvents?.map((pastEvent) => {
                return (
                    <Text key={pastEvent.key} style={styles.card}>
                        {pastEvent.key}
                    </Text>
                );
            })}
            <Button onPress={signOut} title="Sign out" />
        </View>
    );
};
