import { useNavigationState, NavigationState } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { ChallengeSubRoute, GameSubRoute, Route } from '../../config/routes';
import {
    useGetEventState,
    useGetPasswordChallengeHints,
    useSubscribeToAuthUserData,
    useSubscribeToEvent,
    useSubscribeToPrivateUserData,
} from '../../hooks/sixtyThreeAPIHooks';
import { View, Text } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { EventState, EventStateChallengeActiveData, RouteDataGateContextData } from '../../lib/types';
import { useLocalAppData } from '../firebase/storage';

const InnerStack = createNativeStackNavigator();

interface RouteComponentData {
    name: string;
    params: any;
}

const getRouteComponentChainFromState = (state: NavigationState): RouteComponentData[] => {
    const routes = state?.routes;

    if (!routes || routes.length === 0) {
        return [];
    }

    const lastRoute = routes[routes.length - 1];

    const routeComponentData: RouteComponentData = {
        name: lastRoute.name,
        params: lastRoute.params,
    };

    return [routeComponentData, ...getRouteComponentChainFromState(lastRoute.state as NavigationState)];
};

export const RouteDataGateContext = React.createContext<RouteDataGateContextData | null>(null);

interface RouteDataGateProps {
    children: (stack: typeof InnerStack, context: RouteDataGateContextData | null) => React.ReactNode;
}

export const RouteDataGate: React.FC<RouteDataGateProps> = ({ children }) => {
    const navigationState = useNavigationState((state) => state);
    const routeComponentChain = getRouteComponentChainFromState(navigationState);

    const routeComponentChainString = routeComponentChain.map((routeComponent) => routeComponent.name).join('.');

    /*
    These will be the rules for loading data here:

    1. If the data is not needed for the current route, do not load it.

    2. the data can only be in three states:

        * Loading
        * Success
        * Error

    3. All hooks from now on will use the api for the standard firebase js sdk. Later I'll make it work with the mobile apis. For this transition to go smoothly I will wrap every api call in a hook. This will make it easy to switch the api calls later.

    4. MAAAYBE some hooks would need to get the Route as a parameter so they can retrigger when needed. We'll see. They should however clean up after themselves. Meaning, if they are not needed anymore, they should unsubscribe from the realtime updates.

    */

    const [localAppData, isLoadingLocalAppData, localAppDataError] = useLocalAppData();

    // game routes requirements
    const [signedUserData, isLoadingAuthUserData, authUserDataError] = useSubscribeToAuthUserData();
    const [privateUserData, isLoadingPrivateUserData, privateUserDataError] = useSubscribeToPrivateUserData();

    // event routes requirements
    const eventKey = routeComponentChain.find((predicate) => predicate.name === GameSubRoute.EventScreen)?.params
        ?.eventKey;
    const [eventData, isSubscribingToEvent, eventSubscriptionError] = useSubscribeToEvent(eventKey); // subscribing is the term for getting realtime updates from the server, not to be confused with enrolling to an event
    const getEventStateRequest = useMemo(() => {
        if (!eventKey) {
            return null;
        }

        return {
            eventKey,
        };
    }, [eventKey]);

    const [eventStateData, isLoadingEventState, eventStateError] = useGetEventState(getEventStateRequest);

    // challenge hints route requirements

    const getPasswordChallengeHintsRequest = useMemo(() => {
        if (!getEventStateRequest || !eventStateData || eventStateData.state !== EventState.ChallengeActive) {
            return null;
        }
        return {
            eventKey: getEventStateRequest?.eventKey,
            challengeKey: (eventStateData?.stateData as EventStateChallengeActiveData)?.challengeKey,
        };
    }, [getEventStateRequest, eventStateData]);

    const [passwordChallengeHintsData, isLoadingPasswordChallengeHints, passwordChallengeHintsError] =
        useGetPasswordChallengeHints(getPasswordChallengeHintsRequest);

    const loadingDependencies: Array<boolean> = [];
    const errorDependencies: Array<Error | null | undefined> = [];

    if (routeComponentChainString.includes(Route.GameScreen)) {
        loadingDependencies.push(isLoadingLocalAppData);
        errorDependencies.push(localAppDataError);

        loadingDependencies.push(isLoadingAuthUserData);
        errorDependencies.push(authUserDataError);

        loadingDependencies.push(isLoadingPrivateUserData);
        errorDependencies.push(privateUserDataError);
    }

    if (routeComponentChainString.includes(GameSubRoute.EventScreen)) {
        loadingDependencies.push(isSubscribingToEvent);
        errorDependencies.push(eventSubscriptionError);

        loadingDependencies.push(isLoadingEventState);
        errorDependencies.push(eventStateError);
    }

    if (routeComponentChainString.includes(ChallengeSubRoute.HintsScreen)) {
        loadingDependencies.push(isLoadingPasswordChallengeHints);
        errorDependencies.push(passwordChallengeHintsError);
    }

    const isLoading = loadingDependencies.some((dependency) => dependency);

    console.log('loadingDependencies', loadingDependencies);
    console.log('errorDependencies', errorDependencies);

    const routeDataGateContextData: RouteDataGateContextData = {
        localAppData: localAppData ?? undefined,
        signedUserData: signedUserData ?? undefined,
        privateUserData: privateUserData ?? undefined,
        event: eventData ?? undefined,
        eventState: eventStateData ?? undefined,
        passwordChallengeHintsData: passwordChallengeHintsData ?? undefined,
        isLoadingAuthUserData,
        isLoadingPrivateUserData,
        isLoadingEventState,
        isLoadingPasswordChallengeHints,
        isLoadingLocalAppData,
        isSubscribingToEvent,
    };

    return (
        <>
            {isLoading && (
                <View
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'orange',
                        opacity: 0.9,
                        zIndex: 100,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Text>Loading...</Text>
                </View>
            )}
            <RouteDataGateContext.Provider value={routeDataGateContextData}>
                {!isLoadingAuthUserData && ( // this is required for web. Without it, the app would try to render child components not ready to be rendered and thus reset the app's url.
                    <InnerStack.Navigator
                        screenOptions={{
                            header: () => null,
                            animation: 'none',
                        }}
                    >
                        {children(InnerStack, routeDataGateContextData)}
                    </InnerStack.Navigator>
                )}
            </RouteDataGateContext.Provider>
        </>
    );
};
