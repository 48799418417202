// This file should only contain hooks specifically related to the SixtyThree API. Any other hooks that use the firebase api directly should be placed in their appropriate module (e.g. auth.ts, storage.ts, etc.)
import {
    CompleteUserProfileResponse,
    APIFunctionsName,
    ScreenOfFameEntry,
    PrivateUserData,
    Event,
    CompleteUserProfileRequest,
    EventVisibility,
    GetPasswordChallengeHintsRequest,
    GetPasswordChallengeHintsResponse,
    EnrollUserToEventRequest,
    CheckPasswordChallengeResponse,
    CheckPasswordChallengeRequest,
    FirebaseCollection,
    GetEventStateResponse,
    GetEventStateRequest,
    SignedUserData,
} from '../lib/types';
import { useEffect, useState } from 'react';
import { onAuthStateChanged, signInWithApple, signInWithGoogle } from '../app/firebase/auth';
import { useCommonCollectionData, useCommonDocumentData } from '../app/firebase/firestoreCommon';
import { CommonUserCredential } from '../app/firebase/commonAPI';
import { useCommonHttpCallable } from '../app/firebase/functions';
import { webAuth } from '../config/firebase.web';
import { Platform } from 'react-native';
import { mobileAuth } from '../config/firebase';

export function useSubscribeToAuthUserData(): [SignedUserData | null, boolean, Error | null] {
    const [user, setUser] = useState<SignedUserData | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    // Handle user state changes
    useEffect(() => {
        const subscriber = onAuthStateChanged((u) => {
            if (u) {
                const signedUserData: SignedUserData = {
                    displayName: u.displayName,
                    email: u.email,
                    uid: u.uid,
                };
                setUser(signedUserData);
                setError(null);
            } else {
                setUser(null);
                setError(new Error('User not found'));
            }
            if (isLoading) setIsLoading(false);
        });

        return subscriber; // unsubscribe on unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [user, isLoading, error];
}

export function useSubscribeToPrivateUserData(): [PrivateUserData | undefined, boolean, Error | undefined] {
    const [authUserData, isLoadingAuthUserData] = useSubscribeToAuthUserData();

    const [privateUser, isLoadingQuery, error] = useCommonDocumentData<PrivateUserData>(
        authUserData?.uid ? `${FirebaseCollection.PrivateUsers}/${authUserData?.uid}` : undefined,
    );

    return [privateUser, isLoadingAuthUserData || isLoadingQuery, error];
}

export function useSubscribeToCurrentEvent(): [Event | undefined, boolean, Error | undefined] {
    const [snapshot, isLoadingQuery, error] = useCommonCollectionData<Event>(`${FirebaseCollection.Events}`, {
        orderBy: [['createdAt', 'desc']],
        limit: 1,
        where: [
            [
                'visibility',
                'in',
                process.env.NODE_ENV === 'development'
                    ? [EventVisibility.Public, EventVisibility.Beta]
                    : [EventVisibility.Public],
            ],
        ],
    });

    const firstDoc = snapshot?.[0];

    return [firstDoc, isLoadingQuery, error];
}

export function useSubscribeToPastEvents(): [Event[] | undefined, boolean, Error | undefined] {
    const [snapshot, isLoadingQuery, error] = useCommonCollectionData<Event>(`${FirebaseCollection.Events}`, {
        orderBy: [['createdAt', 'asc']],
        where: [
            [
                'visibility',
                'in',
                process.env.NODE_ENV === 'development'
                    ? [EventVisibility.Public, EventVisibility.Beta]
                    : [EventVisibility.Public],
            ],
        ],
    });

    const events = snapshot?.slice(0, -1);

    return [events, isLoadingQuery, error];
}

export function useSubscribeToEvent(eventId?: string): [Event | undefined, boolean, Error | undefined] {
    const [snapshot, isLoadingQuery, error] = useCommonDocumentData<Event>(
        eventId ? `${FirebaseCollection.Events}/${eventId}` : undefined,
    );

    return [snapshot, isLoadingQuery, error];
}

export function useSubscribeToScreenOfFame() {
    return useCommonCollectionData<ScreenOfFameEntry>(`${FirebaseCollection.ScreenOfFame}`, {
        orderBy: [['event', 'desc']],
    });
}

// callables

export function useCompleteUserProfile() {
    const [callable, isLoading, error] = useCommonHttpCallable<CompleteUserProfileRequest, CompleteUserProfileResponse>(
        APIFunctionsName.COMPLETE_USER_PROFILE,
    );

    const completeUserProfile = async (data) => {
        await callable(data);
        if (Platform.OS === 'web') {
            await webAuth.currentUser?.getIdToken(true);
        } else {
            await mobileAuth.currentUser?.getIdToken(true);
        }
    };

    return [completeUserProfile, isLoading, error];
}

export function useCheckPasswordChallenge() {
    return useCommonHttpCallable<CheckPasswordChallengeRequest, CheckPasswordChallengeResponse>(
        APIFunctionsName.CHECK_PASSWORD_CHALLENGE,
    );
}

export function useAutoCallableHook<Request, Response>(
    name: APIFunctionsName,
    data: Request | null,
): [Response | null | undefined, boolean, Error | null | undefined] {
    const [callable, isLoading, error, result] = useCommonHttpCallable<Request, Response>(name);

    useEffect(() => {
        if (data) {
            callable(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return [result, isLoading, error];
}

export function useGetPasswordChallengeHints(data: GetPasswordChallengeHintsRequest | null) {
    return useAutoCallableHook<GetPasswordChallengeHintsRequest, GetPasswordChallengeHintsResponse>(
        APIFunctionsName.GET_PASSWORD_CHALLENGE_HINTS,
        data,
    );
}

export function useEnrollToEvent() {
    return useCommonHttpCallable<EnrollUserToEventRequest, void>(APIFunctionsName.ENROLL_USER_TO_EVENT);
}

export function useGetEventState(data: GetEventStateRequest | null) {
    return useAutoCallableHook<GetEventStateRequest, GetEventStateResponse>(APIFunctionsName.GET_EVENT_STATE, data);
}

export type SignInWithPopupHook = [
    signIn: () => Promise<CommonUserCredential>,
    isLoading: boolean,
    error: Error | null,
];

export const useConfiguredSignInWithGoogle = (): SignInWithPopupHook => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const hookSignInWithGoogle = async (): Promise<CommonUserCredential> => {
        setIsLoading(true);
        try {
            const result = await signInWithGoogle();
            return result;
        } catch (e) {
            setError(e);
            throw e;
        } finally {
            setIsLoading(false);
        }
    };

    return [hookSignInWithGoogle, isLoading, error];
};

export const useConfiguredSignInWithApple = (): SignInWithPopupHook => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const hookSignInWithApple = async (): Promise<CommonUserCredential> => {
        setIsLoading(true);
        try {
            return await signInWithApple();
        } catch (e) {
            setError(e);
            throw e;
        } finally {
            setIsLoading(false);
        }
    };

    return [hookSignInWithApple, isLoading, error];
};
