import { StyleSheet, View, Text, Button } from 'react-native';
import { useConfiguredSignInWithApple, useConfiguredSignInWithGoogle } from '../../../hooks/sixtyThreeAPIHooks';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export const SignUpScreen: React.FC = () => {
    const [signInWithGoogle, isSigningInWithGoogle, googleError] = useConfiguredSignInWithGoogle();
    const [signInWithApple, isSigningInWithApple, appleError] = useConfiguredSignInWithApple();

    const isLoading = isSigningInWithGoogle || isSigningInWithApple;
    const error = googleError || appleError;

    return (
        <View style={styles.container}>
            <Text>Sign up nowwwwww</Text>
            {isLoading && <Text>Loading</Text>}
            <Text>{JSON.stringify(error)}</Text>
            <Button
                onPress={() => {
                    signInWithGoogle();
                }}
                title="Sign in with google"
            />
            <Button
                onPress={() => {
                    signInWithApple();
                }}
                title="Sign in with Apple"
            />
        </View>
    );
};
