import { View, Text } from 'react-native';

export const NotFoundScreen: React.FC = () => {
    return (
        <View
            style={{
                backgroundColor: 'red',
            }}
        >
            <Text>Not found</Text>
        </View>
    );
};
